<template>
    <div class="pagebox" v-loading="loading" element-loading-text="加载中...">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 院校 -->
        <div class="college_box">
            <div class="college_con">
                <!-- 查询、筛选 -->
                <div class="searchbox">
                    <div class="t_box" v-if="SeachForm.ProvinceId!=''||SeachForm.CollegeCategoryId!=''||SeachForm.CollegeNatureId!=''||SeachForm.EducationLevelId!=''||SeachForm.CollegeTagId!=''">
                        <div class="ttxt">筛选条件<i class="el-icon-d-arrow-right"></i></div>
                        <div class="t_data">
                            <div class="data" v-if="SeachForm.ProvinceId!=''">
                                <span class="tit">院校地区：</span>
                                <span class="d" v-for="(item,index) in SeachForm.ProvinceId" :key="index">{{item.Name}}<i class="el-icon-circle-close" @click.prevent="ProvinceCheck(item)"></i></span>
                                <div class="close" @click.prevent="ProvinceCheck('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="SeachForm.CollegeCategoryId!=''">
                                <span class="tit">院校类别：</span>
                                <span class="d" v-for="(item,index) in SeachForm.CollegeCategoryId" :key="index">{{item.CategoryName}}<i class="el-icon-circle-close" @click.prevent="CollegeCategoryCheck(item)"></i></span>
                                <div class="close" @click.prevent="CollegeCategoryCheck('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="SeachForm.CollegeNatureId!=''">
                                <span class="tit">办学性质：</span>
                                <span class="d" v-for="(item,index) in SeachForm.CollegeNatureId" :key="index">{{item.Name}}<i class="el-icon-circle-close" @click.prevent="CollegeNatureCheck(item)"></i></span>
                                <div class="close" @click.prevent="CollegeNatureCheck('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="SeachForm.EducationLevelId!=''">
                                <span class="tit">办学层次：</span>
                                <span class="d" v-for="(item,index) in SeachForm.EducationLevelId" :key="index">{{item.Name}}<i class="el-icon-circle-close" @click.prevent="EducationLeveCheck(item)"></i></span>
                                <div class="close" @click.prevent="EducationLeveCheck('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="data" v-if="SeachForm.CollegeTagId!=''">
                                <span class="tit">特色标签：</span>
                                <span class="d" v-for="(item,index) in SeachForm.CollegeTagId" :key="index">{{item.Name}}<i class="el-icon-circle-close" @click.prevent="CollegeTagCheck(item)"></i></span>
                                <div class="close" @click.prevent="CollegeTagCheck('')"><i class="el-icon-close"></i></div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="m_box" id="college_search_item">
                        <div class="mbtn" id="college_search_down" @click.prevent="CollegeSearchBtn(1)">展开筛选条件<i class="el-icon-d-arrow-right"></i></div>
                        <div class="mbtn" id="college_search_up"  @click.prevent="SeachFormSubmit" style="display:none;">收起筛选条件<i class="el-icon-d-arrow-right"></i></div>
                        <div class="m_list" id="college_list_box">
                            <div class="s_box">
                                <div class="tit">院校地区：</div>
                                <div class="box">
                                    <span class="item" :class="(SeachForm.ProvinceId==''?'on':'')" @click.prevent="ProvinceCheck('')">不限</span>
                                    <span class="item" :class="(SeachForm.ProvinceId.find(f=>f.ID==item.ID)?'on':'')" v-for="(item,index) in ProvinceData" :key="index" @click.prevent="ProvinceCheck(item)">{{item.Name}}</span>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="s_box">
                                <div class="tit">院校类别：</div>
                                <div class="box">
                                    <span class="item" :class="(SeachForm.CollegeCategoryId==''?'on':'')" @click.prevent="CollegeCategoryCheck('')">不限</span>
                                    <span class="item" :class="(SeachForm.CollegeCategoryId.find(f=>f.ID==item.ID)?'on':'')" v-for="(item,index) in CollegeCategoryData" :key="index" @click.prevent="CollegeCategoryCheck(item)">{{item.CategoryName}}</span>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="s_box">
                                <div class="tit">办学性质：</div>
                                <div class="box">
                                    <span class="item" :class="(SeachForm.CollegeNatureId==''?'on':'')" @click.prevent="CollegeNatureCheck('')">不限</span>
                                    <span class="item" :class="(SeachForm.CollegeNatureId.find(f=>f.Value==item.Value)?'on':'')" v-for="(item,index) in CollegeNatureData" :key="index" @click.prevent="CollegeNatureCheck(item)">{{item.Name}}</span>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="s_box">
                                <div class="tit">办学层次：</div>
                                <div class="box">
                                    <span class="item" :class="(SeachForm.EducationLevelId==''?'on':'')" @click.prevent="EducationLeveCheck('')">不限</span>
                                    <span class="item" :class="(SeachForm.EducationLevelId.find(f=>f.ID==item.ID)?'on':'')" v-for="(item,index) in EducationLevelData" :key="index" @click.prevent="EducationLeveCheck(item)">{{item.Name}}</span>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="s_box">
                                <div class="tit">特色标签：</div>
                                <div class="box">
                                    <span class="item" :class="(SeachForm.CollegeTagId==''?'on':'')" @click.prevent="CollegeTagCheck('')">不限</span>
                                    <span class="item" :class="(SeachForm.CollegeTagId.find(f=>f.Value==item.Value)?'on':'')" v-for="(item,index) in CollegeTagData" :key="index" @click.prevent="CollegeTagCheck(item)">{{item.Name}}</span>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="s_submit" @click.prevent="SeachFormSubmit"><i class="el-icon-search"></i> 查 询</div>
                        </div>
                    </div>
                    <div class="i_box">
                        <div class="num">共找到 <span class="n">{{CollegeNumber}}</span> 所院校</div>
                        <div class="stxt">
                            <el-input placeholder="搜索院校" v-model="SeachForm.CollegeName" clearable></el-input>
                        </div>
                        <div class="s_btn"><i class="el-icon-search"></i>搜 索</div>
                        <div class="clear"></div>
                    </div>
                </div>
                <!-- 列表 -->
                <div class="college_list">
                    <div class="college_box" v-for="(item,index) in CollegeData" :key="index">
                        <div class="logo" @click.prevent="CollegeDetail(item.Id)" :style="{'background':'url(https://jzy-dev.obs.cn-north-4.myhuaweicloud.com/UserFile/logo/'+(item.Id)+'.jpg) no-repeat center center / cover'}"></div>
                        <div class="mark">
                            <div>
                                <span class="name" @click.prevent="CollegeDetail(item.Id)">{{item.CollegeName}}</span>
                                <div class="con">
                                    <span class="txt"><i class="iconfont">&#xe615;</i>北京市</span>
                                    <span class="txt"><i class="iconfont">&#xe679;</i>教育部 · 公立 · 综合</span>
                                    <span class="txt"><i class="iconfont">&#xe661;</i>本科</span>
                                    <span class="txt"></span>
                                </div>
                            </div>
                            <div class="dress">
                                <span><i class="el-icon-location-outline"></i>北京市海淀区清华园1号</span>
                                <span><i class="el-icon-phone-outline"></i>010-62770334, 62782051</span>
                            </div>
                            <div class="tips" :id="'college_tip_id'+item.Id">
                                <span>985</span>
                                <span>211</span>
                                <span>双一流</span>
                                <span>研究生院</span>
                                <span>国重点</span>
                                <span>保研</span>
                                <span>博士授权学科</span>
                                <span>九校联盟</span>
                                <span>中国高校行星科学联盟</span>
                                <span>电气二龙四虎</span>
                                <span>建筑老八校</span>
                                <span>卓越医生</span>
                                <span>卓越法律</span>
                                <span>卓越工程师</span>
                                <div class="more" :id="'college_tip_down'+item.Id" @click.prevent="CollegeTipBtn(item.Id,1)">展开 <i class="el-icon-arrow-down"></i></div>
                                <div class="more" :id="'college_tip_up'+item.Id" @click.prevent="CollegeTipBtn(item.Id,2)" style="display:none;">收起 <i class="el-icon-arrow-up"></i></div>
                            </div>
                        </div>
                        <div class="btnbox">
                            <div class="btn add" :id="'college_compare_add'+item.Id" @click.prevent="CollegeAddCompare(item,1)">添加对比<i class="el-icon-plus"></i></div>
                            <div class="btn remove" :id="'college_compare_remove'+item.Id" @click.prevent="CollegeAddCompare(item,2)" style="display:none;">移除对比<i class="el-icon-minus"></i></div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="pages" @click.prevent="pagesbtn()">
                        <i class="iconfont" v-if="CollegeData.length==0&&pageIndex==1">&#xe67c;</i>
                        <span>{{pagetxt}}</span>
                    </div>
                </div>
                <!-- 对比 -->
                <div class="compare_box" :style="{'right':CompareFixed+'px'}" :class="AnimateCompareClass">
                    <div class="compare">
                        <div class="open" @click.prevent="OpenCompareBtn">
                            <div class="ico">
                                <i class="el-icon-d-arrow-right" v-if="CompareFixed==0"></i>
                                <i class="el-icon-d-arrow-left" v-else></i>
                            </div>
                            <div class="tit">{{CompareFixed==0?'收起院校对比':'展开院校对比'}}
                                <div class="num" v-if="CollegeCompareData.length>0">{{CollegeCompareData.length}}</div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="db" v-if="CollegeCompareData.length>0" @click.prevent="StartCompareBtn"><span>开始对比</span></div>
                        <div class="list">
                            <div class="search">
                                <i class="el-icon-search"></i>
                                <el-select v-model="CompareForm.CollegeId" clearable filterable remote reserve-keyword placeholder="请输入院校关键字" @change="CompareCollegeChange" :remote-method="RemoteMethodCollege" :loading="cloading">
                                    <el-option v-for="item in CollegeSelectData" :key="item.ID" :label="item.SchoolName" :value="item.ID" :disabled="item.Disabled" :title="(item.Disabled?'列表中已存在：'+item.SchoolName:'')"></el-option>
                                </el-select>
                            </div>
                            <div class="dlist">
                                <el-scrollbar wrap-class="scrollbar-wrapper" class="comparescroll">
                                    <div class="dl" :class="(CollegeCompareCheckData.find(f=>f.Id===item.Id)?'on':'')" v-for="(item,index) in CollegeCompareData" :key="index">
                                        <span class="check" @click.prevent="CollegeCheck(item)"><span class="ch"><i class="el-icon-check"></i></span></span>
                                        <div class="sname" @click.prevent="CollegeCheck(item)">
                                            <el-tooltip effect="light" :disabled="(item.CollegeName.length>11?false:true)" :content="item.CollegeName" placement="left">
                                                <el-button>{{item.CollegeName}}</el-button>
                                            </el-tooltip>
                                        </div>
                                        <i class="delete el-icon-delete" @click.prevent="CollegeAddCompare(item,2)"></i>
                                    </div>
                                    <div v-if="CollegeCompareData.length==0" style="text-align:center;color:#888;font-size:13px;line-height:60px;">请选择要对比的院校</div>
                                </el-scrollbar>
                                <div class="mark">每次最多可选5条记录对比</div>
                                <div class="btn" @click.prevent="StartCompareBtn">开始对比</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="close_layer_back" id="close_layer_back" @click.prevent="SeachFormSubmit"></div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
export default {
    components:{
        PHeader,
        PFooter
    },
    data(){
        return {
            loading:false,
            pageIndex:1,
            pagetxt:'未查询到院校',
            HasNext:false,
            ProvinceData:[],
            CollegeCategoryData:[],
            CollegeNatureData:[],
            EducationLevelData:[],
            CollegeTagData:[],
            CollegeNumber:2923,
            CollegeData:[],
            CompareFixed:-240,
            AnimateCompareClass:'',
            SeachCollegeShow:false,
            SeachForm:{
                CollegeName:'',
                ProvinceId:[],
                CollegeCategoryId:[],
                CollegeNatureId:[],
                EducationLevelId:[],
                CollegeTagId:[]
            },
            CompareForm:{
                CollegeId:null
            },
            CollegeSelectData:[],
            cloading:false,
            CollegeCompareData:[],
            CollegeCompareCheckData:[]
        }
    },
    mounted(){
        this.GetSearchData();// 获取查询条件数据
        this.GetCollegeList();// 获取院校
    },
    methods:{
        // 地区选择
        ProvinceCheck(item){
            if(item!==''){
                let obj = this.SeachForm.ProvinceId.find(f=>f.ID==item.ID);
                if(obj){
                    this.SeachForm.ProvinceId = this.SeachForm.ProvinceId.filter(f=>f.ID!==item.ID);
                }
                else{
                    this.SeachForm.ProvinceId.push(item);
                }
            }
            else{
                this.SeachForm.ProvinceId = [];
            }
        },
        // 院校类别选择
        CollegeCategoryCheck(item){
            if(item!==''){
                let obj = this.SeachForm.CollegeCategoryId.find(f=>f.ID==item.ID);
                if(obj){
                    this.SeachForm.CollegeCategoryId = this.SeachForm.CollegeCategoryId.filter(f=>f.ID!==item.ID);
                }
                else{
                    this.SeachForm.CollegeCategoryId.push(item);
                }
            }
            else{
                this.SeachForm.CollegeCategoryId = [];
            }
        },
        // 办学性质选择
        CollegeNatureCheck(item){
            if(item!==''){
                let obj = this.SeachForm.CollegeNatureId.find(f=>f.Value==item.Value);
                if(obj){
                    this.SeachForm.CollegeNatureId = this.SeachForm.CollegeNatureId.filter(f=>f.Value!==item.Value);
                }
                else{
                    this.SeachForm.CollegeNatureId.push(item);
                }
            }
            else{
                this.SeachForm.CollegeNatureId = [];
            }
        },
        // 办学层次选择
        EducationLeveCheck(item){
            if(item!==''){
                let obj = this.SeachForm.EducationLevelId.find(f=>f.ID==item.ID);
                if(obj){
                    this.SeachForm.EducationLevelId = this.SeachForm.EducationLevelId.filter(f=>f.ID!==item.ID);
                }
                else{
                    this.SeachForm.EducationLevelId.push(item);
                }
            }
            else{
                this.SeachForm.EducationLevelId = [];
            }
        },
        // 院校标签选择
        CollegeTagCheck(item){
            if(item!==''){
                let obj = this.SeachForm.CollegeTagId.find(f=>f.Value==item.Value);
                if(obj){
                    this.SeachForm.CollegeTagId = this.SeachForm.CollegeTagId.filter(f=>f.Value!==item.Value);
                }
                else{
                    this.SeachForm.CollegeTagId.push(item);
                }
            }
            else{
                this.SeachForm.CollegeTagId = [];
            }
        },
        // 获取查询条件数据
        GetSearchData(){
            this.ProvinceData=[
                {
                    "ID": "cf39e965-98c6-488e-a969-c6c25781cba6",
                    "Name": "北京",
                    "Code": "1"
                },
                {
                    "ID": "614de43a-4d17-4639-a19c-3221b0e80e0b",
                    "Name": "江苏",
                    "Code": "10"
                },
                {
                    "ID": "830ac47a-b3dd-4e94-8cfc-1af0e4b43f45",
                    "Name": "浙江",
                    "Code": "11"
                },
                {
                    "ID": "38220a9c-2afa-4a42-a18e-71876bb5d629",
                    "Name": "安徽",
                    "Code": "12"
                },
                {
                    "ID": "4a3353d7-9b17-4325-8004-7effdbe2044f",
                    "Name": "福建",
                    "Code": "13"
                },
                {
                    "ID": "7cae0edd-a5df-403b-9853-b1529fdfdad1",
                    "Name": "江西",
                    "Code": "14"
                },
                {
                    "ID": "7f42bbf2-968a-40a6-9e64-3b2383d1eb43",
                    "Name": "山东",
                    "Code": "15"
                },
                {
                    "ID": "b7df5aca-27ce-408d-8c7d-2c3ae76cbb3d",
                    "Name": "河南",
                    "Code": "16"
                },
                {
                    "ID": "6bd52781-2f8e-4114-8150-5deb3ac228d3",
                    "Name": "湖北",
                    "Code": "17"
                },
                {
                    "ID": "f82a7d17-bfa4-4f55-983c-1dd747369722",
                    "Name": "湖南",
                    "Code": "18"
                },
                {
                    "ID": "b8d010c8-f621-40a9-86dd-d8edbf32e848",
                    "Name": "广东",
                    "Code": "19"
                },
                {
                    "ID": "0c500669-c9b5-48d0-b848-3ccf71e6f53b",
                    "Name": "天津",
                    "Code": "2"
                },
                {
                    "ID": "c2ec2b69-62ff-474d-a22e-e57a4b42b5ee",
                    "Name": "广西",
                    "Code": "20"
                },
                {
                    "ID": "52e24f98-63b3-47f0-960c-45015d6d7b3f",
                    "Name": "海南",
                    "Code": "21"
                },
                {
                    "ID": "ae4cab95-53b8-4b93-a48a-59ebaf39814c",
                    "Name": "重庆",
                    "Code": "22"
                },
                {
                    "ID": "c9384eb7-f73e-4501-bcb5-325710efcc8b",
                    "Name": "四川",
                    "Code": "23"
                },
                {
                    "ID": "54cb098b-256e-4487-974f-511ae8ca1ca5",
                    "Name": "贵州",
                    "Code": "24"
                },
                {
                    "ID": "d0c9d87f-a620-4763-a790-f7e9a05d7928",
                    "Name": "云南",
                    "Code": "25"
                },
                {
                    "ID": "d1d97ae4-d369-4e67-9854-382ff768adaa",
                    "Name": "西藏",
                    "Code": "26"
                },
                {
                    "ID": "73979981-355c-4316-91d0-839e759d0bb7",
                    "Name": "陕西",
                    "Code": "27"
                },
                {
                    "ID": "90940f2d-7f2e-4258-b246-49dac7eb2a79",
                    "Name": "甘肃",
                    "Code": "28"
                },
                {
                    "ID": "821ca668-6605-4c5b-bbe7-759bb1c29e01",
                    "Name": "青海",
                    "Code": "29"
                },
                {
                    "ID": "6f6cedc9-933c-429d-a79c-54f667936e81",
                    "Name": "河北",
                    "Code": "3"
                },
                {
                    "ID": "ffa11601-2147-42bb-aad3-b33bf90ae07a",
                    "Name": "宁夏",
                    "Code": "30"
                },
                {
                    "ID": "ca8d78b7-b906-4261-95e0-305335ffea6d",
                    "Name": "新疆",
                    "Code": "31"
                },
                {
                    "ID": "4c0122c9-df13-447e-84ee-af51dcb5c0d9",
                    "Name": "山西",
                    "Code": "4"
                },
                {
                    "ID": "220f1ffe-86f7-4698-a972-508238966f84",
                    "Name": "内蒙古",
                    "Code": "5"
                },
                {
                    "ID": "a949c8b6-40bb-456a-af55-fb4f6fbcd947",
                    "Name": "辽宁",
                    "Code": "6"
                },
                {
                    "ID": "0e5dcbfe-75da-4319-8ba8-ae08ee1c4c19",
                    "Name": "吉林",
                    "Code": "7"
                },
                {
                    "ID": "e57ab023-3b98-48cd-839f-d29922a976a5",
                    "Name": "黑龙江",
                    "Code": "8"
                },
                {
                    "ID": "5f4ff010-4ff3-4442-b65a-4d3870d71254",
                    "Name": "上海",
                    "Code": "9"
                }
            ];
            this.CollegeCategoryData= [
                {
                    "ID": "f45d0690-ed1e-45c4-b724-a1b88d4d725b",
                    "CategoryName": "综合"
                },
                {
                    "ID": "5cb76c35-85aa-439b-8f83-84970ff3e7e0",
                    "CategoryName": "理工"
                },
                {
                    "ID": "bed9caca-eb8d-4ff9-9c95-3174eea42cc9",
                    "CategoryName": "财经"
                },
                {
                    "ID": "6a2c90b4-4561-48ae-872c-92ceb5eb71bd",
                    "CategoryName": "农林"
                },
                {
                    "ID": "820c9da2-65b4-4f0a-ae2e-03307f2e6b93",
                    "CategoryName": "医药"
                },
                {
                    "ID": "7abc291b-d557-4149-bf83-2200e5764eec",
                    "CategoryName": "师范"
                },
                {
                    "ID": "fe2f7610-b87e-4933-8b85-a79a42f3199f",
                    "CategoryName": "体育"
                },
                {
                    "ID": "0109930c-f8f4-4bf5-86de-128e5a81e2ee",
                    "CategoryName": "政法"
                },
                {
                    "ID": "f5fe1373-756a-4f95-be71-a7e7af5b6b06",
                    "CategoryName": "艺术"
                },
                {
                    "ID": "9ecd37ae-98ae-4817-a97d-7e9d6934d4ec",
                    "CategoryName": "民族"
                },
                {
                    "ID": "7aaf30c8-3f45-4f99-aadc-2561ad77619e",
                    "CategoryName": "军事"
                },
                {
                    "ID": "b5ab86eb-e61f-48dd-a32f-5e41ca50bda0",
                    "CategoryName": "语言"
                },
                {
                    "ID": "44e15c4e-6e68-4664-9dd5-557b4e59db7f",
                    "CategoryName": "其它"
                }
            ];
            this.CollegeNatureData= [
                {
                    "Name": "公立",
                    "Value": "1"
                },
                {
                    "Name": "民办",
                    "Value": "2"
                },
                {
                    "Name": "中外合作",
                    "Value": "3"
                },
                {
                    "Name": "内地与港澳台合作",
                    "Value": "4"
                }
            ];
            this.EducationLevelData=[
                { Name: '本科', ID: 1 },
                { Name: '高职高专', ID: 0 }
            ];
            this.CollegeTagData= [
                {
                    "Name": "985",
                    "Value": "1"
                },
                {
                    "Name": "211",
                    "Value": "2"
                },
                {
                    "Name": "双一流",
                    "Value": "3"
                },
                {
                    "Name": "研究生院",
                    "Value": "10"
                },
                {
                    "Name": "国重点",
                    "Value": "11"
                },
                {
                    "Name": "省重点",
                    "Value": "12"
                },
                {
                    "Name": "保研",
                    "Value": "13"
                },
                {
                    "Name": "博士授权学科",
                    "Value": "14"
                },
                {
                    "Name": "九校联盟",
                    "Value": "21"
                },
                {
                    "Name": "卓越大学联盟",
                    "Value": "22"
                },
                {
                    "Name": "国防七子",
                    "Value": "23"
                },
                {
                    "Name": "兵工七子",
                    "Value": "24"
                },
                {
                    "Name": "法学五院四系",
                    "Value": "25"
                },
                {
                    "Name": "工业和信息化部高校联盟",
                    "Value": "26"
                },
                {
                    "Name": "中国高校行星科学联盟",
                    "Value": "27"
                },
                {
                    "Name": "六大农林",
                    "Value": "28"
                },
                {
                    "Name": "电气二龙四虎",
                    "Value": "29"
                },
                {
                    "Name": "原电力部直属高校",
                    "Value": "30"
                },
                {
                    "Name": "原铁道部直属高校",
                    "Value": "31"
                },
                {
                    "Name": "原邮电部直属高校",
                    "Value": "32"
                },
                {
                    "Name": "原电子工业部直属高校",
                    "Value": "33"
                },
                {
                    "Name": "原航空航天部直属高校",
                    "Value": "34"
                },
                {
                    "Name": "原财政部直属高校",
                    "Value": "35"
                },
                {
                    "Name": "中国民用航空局直属",
                    "Value": "36"
                },
                {
                    "Name": "国家烟草专卖局共建高校",
                    "Value": "37"
                },
                {
                    "Name": "四大工学院",
                    "Value": "38"
                },
                {
                    "Name": "两电一邮",
                    "Value": "39"
                },
                {
                    "Name": "两财一贸",
                    "Value": "40"
                },
                {
                    "Name": "六大财经",
                    "Value": "41"
                },
                {
                    "Name": "九大师范",
                    "Value": "42"
                },
                {
                    "Name": "十大医学院",
                    "Value": "43"
                },
                {
                    "Name": "建筑老八校",
                    "Value": "51"
                },
                {
                    "Name": "建筑新八校",
                    "Value": "52"
                },
                {
                    "Name": "卓越医生",
                    "Value": "53"
                },
                {
                    "Name": "卓越医生(中医)",
                    "Value": "54"
                },
                {
                    "Name": "卓越法律",
                    "Value": "55"
                },
                {
                    "Name": "卓越工程师",
                    "Value": "56"
                },
                {
                    "Name": "卓越农林",
                    "Value": "57"
                },
                {
                    "Name": "卓越教师",
                    "Value": "58"
                },
                {
                    "Name": "CDIO工程教育联盟",
                    "Value": "59"
                },
                {
                    "Name": "中西部100所",
                    "Value": "60"
                },
                {
                    "Name": "高水平学校建设单位(A档)",
                    "Value": "1101"
                },
                {
                    "Name": "高水平学校建设单位(B档)",
                    "Value": "1102"
                },
                {
                    "Name": "高水平学校建设单位(C档)",
                    "Value": "1103"
                },
                {
                    "Name": "高水平专业群建设单位(A档)",
                    "Value": "1104"
                },
                {
                    "Name": "高水平专业群建设单位(B档)",
                    "Value": "1105"
                },
                {
                    "Name": "高水平专业群建设单位(C档)",
                    "Value": "1106"
                },
                {
                    "Name": "国家示范性高等职业院校",
                    "Value": "1107"
                },
                {
                    "Name": "国家骨干高职院校",
                    "Value": "1108"
                },
                {
                    "Name": "优质专科高等职业院校",
                    "Value": "1109"
                }
            ];
        },
        // 院校筛选条件展开/收起
        CollegeSearchBtn(type){
            if(type==1){
                document.getElementById('college_search_down').style.display='none';
                document.getElementById('college_search_up').style.display='block';
                document.getElementById('college_search_item').style.overflow='initial';
                document.getElementById('close_layer_back').style.display='block';
                document.getElementById('close_layer_back').classList.add("layerbackshow");
                document.getElementById('close_layer_back').classList.remove("layerbackhide");
                let obj = document.getElementById("college_list_box");
                obj.style.display='block';
                obj.classList.remove("layerboxhide");
                obj.classList.add("layerboxshow");
            }
            else{
                document.getElementById('college_search_down').style.display='block';
                document.getElementById('college_search_up').style.display='none';
                document.getElementById('close_layer_back').classList.add("layerbackhide");
                document.getElementById('close_layer_back').classList.remove("layerbackshow");
                let obj = document.getElementById("college_list_box");
                obj.classList.remove("layerboxshow");
                obj.classList.add("layerboxhide");
                let st = setTimeout(()=>{
                    document.getElementById('close_layer_back').style.display='none';
                    document.getElementById('college_search_item').style.overflow='hidden';
                    obj.style.display='none';
                    clearTimeout(st);
                },300);
            }
        },
        // 筛选提交
        SeachFormSubmit(){
            // console.log(this.SeachForm)
            this.CollegeSearchBtn(2);//收起院校筛选条件
            this.GetCollegeList();// 获取院校
        },
        // 获取院校
        GetCollegeList(){
            this.CollegeData = [
                {Id:'1',CollegeName:'四川大学'},
                {Id:'2',CollegeName:'四川农业大学'},
                {Id:'3',CollegeName:'四川师范大学'},
                {Id:'4',CollegeName:'四川电子机械职业技术学院'},
                {Id:'5',CollegeName:'四川轻化工大学'},
                {Id:'6',CollegeName:'四川外国语大学'},
                {Id:'7',CollegeName:'四川音乐学院'},
                {Id:'8',CollegeName:'四川旅游学院'},
                {Id:'9',CollegeName:'四川工业科技学院'},
                {Id:'10',CollegeName:'四川商务职业学院'},
            ]
            if(this.CollegeData.length>0){
                if(this.HasNext){
                    this.pagetxt='点击加载更多';
                }
                else{
                    this.pagetxt='已经到底啦';
                }
            }
            else{
                this.pagetxt='未查询到院校';
            }
        },
        // 详情
        CollegeDetail(id){
            this.$router.push("/collegeinfo?id="+id);
        },
        // 院校标签展开/收起
        CollegeTipBtn(id,type){
            let obj1 = document.getElementById('college_tip_id'+id).style;
            let obj2 = document.getElementById('college_tip_down'+id).style;
            let obj3 = document.getElementById('college_tip_up'+id).style;
            if(type==1){
                obj1.height='auto';obj1.overflow='initial';obj2.display='none';obj3.display='block';
            }
            else{
                obj1.height='24px';obj1.overflow='hidden';obj2.display='block';obj3.display='none';
            }
        },
        // 院校对比展开/收起
        OpenCompareBtn(){
            if(this.CompareFixed==0){
                this.AnimateCompareClass = 'animate_close';
                this.CompareFixed=-240;
            }
            else{
                this.AnimateCompareClass = 'animate_open';
                this.CompareFixed=0;
            }
        },
        // 院校对比添加/移除
        CollegeAddCompare(item,type){
            if(type==1){
                if(document.getElementById('college_compare_add'+item.Id)){
                    document.getElementById('college_compare_add'+item.Id).style.display='none';
                    document.getElementById('college_compare_remove'+item.Id).style.display='block';
                }
                this.CollegeCompareData.push(item);
                if(this.CollegeCompareCheckData.length<5){
                    this.CollegeCompareCheckData.push(item);
                }
            }
            else{
                if(document.getElementById('college_compare_add'+item.Id)){
                    document.getElementById('college_compare_add'+item.Id).style.display='block';
                    document.getElementById('college_compare_remove'+item.Id).style.display='none';
                }
                this.CollegeCompareData = this.CollegeCompareData.filter(f=>f.Id!==item.Id);
                if(this.CollegeCompareCheckData.find(f=>f.Id===item.Id)){
                    this.CollegeCompareCheckData = this.CollegeCompareCheckData.filter(f=>f.Id!==item.Id);
                }
            }
        },
        // 勾选院校对比
        CollegeCheck(item){
            if(this.CollegeCompareCheckData.find(f=>f.Id===item.Id)){
                this.CollegeCompareCheckData = this.CollegeCompareCheckData.filter(f=>f.Id!==item.Id);
            }
            else{
                if(this.CollegeCompareCheckData.length<5){
                    this.CollegeCompareCheckData.push(item);
                }
                else{
                    this.$message({message: '每次最多可选5条记录对比',type: 'info'});
                }
            }
        },
        // 院校对比下拉改变
        CompareCollegeChange(id){
            this.CompareForm.CollegeId=null;
            let obj = this.CollegeCompareData.find(f=>f.Id==id);
            if(obj){
                this.$message({message: '列表中已存在：'+obj.CollegeName,type: 'info'});return false;
            }
            let item = this.CollegeSelectData.find(f=>f.Id==id);
            if(id&&item){
                this.CollegeAddCompare(item,1);// 院校对比添加
            }
            this.CollegeSelectData = [];
        },
        // 远程搜索院校
        RemoteMethodCollege(query) {
            if (query.trim()) {
                this.cloading = true;
                let st = setTimeout(() => {
                    // 获取院校
                    API.School.SchoolListBySchoolName({PageIndex:1,PageSize:100000,SchoolName:query}).then(res => {
                        this.CollegeSelectData = res.data.Data;
                        this.CollegeSelectData.forEach(item=>{
                            item.Id = item.ID;
                            item.CollegeName = item.SchoolName;
                            if(this.CollegeCompareData.find(f=>f.Id==item.Id)){
                                item.Disabled = true;
                            }
                        });
                        this.cloading = false;
                        clearTimeout(st);
                    });
                }, 200);
            } else {
                this.CollegeSelectData = [];
            }
        },
        // 开始对比
        StartCompareBtn(){
            // console.log(this.CollegeCompareCheckData)
            if(this.CollegeCompareData.length==0){
                this.$message({message: '请添加要对比的院校',type: 'error'});return false;
            }
            if(this.CollegeCompareCheckData.length==0){
                this.$message({message: '请勾选要对比的院校',type: 'error'});return false;
            }
            if(this.CollegeCompareCheckData.length<2){
                this.$message({message: '至少要勾选2个院校',type: 'error'});return false;
            }
            this.$router.push("/compare");
        },
        //加载更多
        pagesbtn(){
            if(this.HasNext){
                if(this.pagetxt=='点击加载更多'){
                    this.pageIndex+=1;
                    this.GetCollegeList();
                }
            }else{
                if(this.CollegeData.length!=0){
                    this.pagetxt='已经到底啦！';
                }
            }
        },
        
    }
}
</script>
<style scoped lang="less">
.college_box{
    max-width: 1420px;
    margin: 20px auto 50px;
    user-select: none;
    .college_con{
        margin: 0px 20px;
        border: 1px solid #eee;
        box-shadow: 0px 0px 3px 1px #ebeaea;
        .searchbox{
            padding: 20px;
            background-color: #fff;
            border-bottom: 1px solid #ebe9e9;
            box-shadow: 0px 0px 3px 1px #ebeaea;
            .i_box{
                .num{
                    float: left;
                    width: 150px;
                    line-height: 40px;
                    color: #666;
                    font-size: 14px;
                    .n{
                        font-size: 16px;
                        color: rgba(var(--themecolor),0.75);
                    }
                }
                .stxt{
                    position: relative;
                    float: left;
                    width: calc(100% - 360px);
                    height: 40px;
                    input{
                        display: block;
                        width: 100%;
                        height: 100%;
                        line-height: 38px;
                        border: none;
                        line-height: 1;
                        text-indent: 20px;
                        color: #666;
                        font-size: 14px;
                        background-color: #fff;
                    }
                    i{
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        z-index: 1;
                        width: 38px;
                        height: 100%;
                        text-align: center;
                        line-height: 38px;
                        color: #999;
                        font-size: 16px;
                    }
                    i:hover{
                        cursor: pointer;
                        color: #666;
                    }
                }
                .s_btn{
                    width: 200px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    color: #fff;
                    font-size: 15px;
                    border-radius: 3px;
                    background-color: rgba(var(--themecolor),0.75);
                    float: right;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    i{
                        margin-right: 10px;
                    }
                }
                .s_btn:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),1);
                }
            }
            .m_box{
                width: 100%;
                height: 38px;
                margin-bottom: 20px;
                position: relative;
                overflow: hidden;
                z-index: 20;
                .mbtn{
                    height: 36px;
                    line-height: 36px;
                    border: 1px solid #d1d0d0;
                    border-radius:5px;
                    text-align: center;
                    color: #666;
                    font-size: 15px;
                    letter-spacing: 1px;
                    background-color: #faefef;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    i{
                        margin-left: 3px;
                    }
                }
                .mbtn:hover,#college_search_up{
                    cursor: pointer;
                    color: rgba(var(--themecolor),1);
                    background-color: #f7f7f7;
                }
                #college_search_down i{
                    transform: rotate(90deg);
                }
                #college_search_up i{
                    transform: rotate(-90deg);
                }
                .m_list{
                    padding: 15px 0;
                    border: 1px solid #ddd;
                    border-top: none;
                    position: absolute;
                    top: 39px;
                    left: 0px;
                    width: calc(100% - 2px);
                    z-index: 16;
                    background-color: rgba(255,255,255,9);
                    box-shadow: 0px 3px 5px 0px #d4d2d2;
                    border-radius: 5px;
                    opacity: 0;
                    display: none;
                    .s_box{
                        margin: 0 20px 15px 20px;
                        border-bottom: 1px dashed #c4c2c2;
                        padding-bottom: 5px;
                        .tit{
                            color: #666;
                            font-size: 14px;
                            float: left;
                            width: 80px;
                            text-align: right;
                        }
                        .box{
                            width: calc(100% - 100px);
                            float: right;
                            .item{
                                display: inline-block;
                                color: #666;
                                font-size: 12px;
                                padding: 2px 18px;
                                border: 1px solid #e2e2e2;
                                border-radius: 20px;
                                margin: 0px 8px 10px;
                                transition: all 0.2s;
                                -webkit-transition: all 0.2s;
                            }
                            .item:hover{
                                
                                border: 1px solid rgba(var(--themecolor),0.75);
                                cursor: pointer;
                                color: rgba(var(--themecolor),0.75);
                            }
                            .item.on{
                                border: 1px solid rgba(var(--themecolor),0.75);
                                background-color: rgba(var(--themecolor),0.75);
                                color: #fff;
                                cursor: default;
                            }
                        }
                    }
                    .s_submit{
                        width: 200px;
                        height: 40px;
                        line-height: 40px;
                        border-radius: 3px;
                        background-color: rgba(var(--themecolor),0.75);
                        color: #fff;
                        font-size: 15px;
                        letter-spacing: 2px;
                        text-align: center;
                        margin: auto;
                    }
                    .s_submit:hover{
                        cursor: pointer;
                        background-color: rgba(var(--themecolor),1);
                    }
                }
                .m_list.layerboxshow {
                    animation: layerboxshow 0.3s ease 0s 1;
                    -webkit-animation: layerboxshow 0.3s ease 0s 1;
                    animation-fill-mode: forwards;
                    -webkit-animation-fill-mode: forwards;
                    @-webkit-keyframes layerboxshow {
                        from {
                            top: -100px;
                            opacity: 0;
                        }
                        to {
                            top: 39px;
                            opacity: 1;
                        }
                    }
                    @keyframes layerboxshow {
                        from {
                            top: -100px;
                            opacity: 0;
                        }
                        to {
                            top: 39px;
                            opacity: 1;
                        }
                    }
                }
                .m_list.layerboxhide {
                    animation: layerboxhide 0.3s ease 0s 1;
                    -webkit-animation: layerboxhide 0.3s ease 0s 1;
                    animation-fill-mode: forwards;
                    -webkit-animation-fill-mode: forwards;
                    @-webkit-keyframes layerboxhide {
                        from {
                            top: 39px;
                            opacity: 1;
                        }
                        to {
                            top: -100px;
                            opacity: 0;
                        }
                    }
                    @keyframes layerboxhide {
                        from {
                            top: 39px;
                            opacity: 1;
                        }
                        to {
                            top: -100px;
                            opacity: 0;
                        }
                    }
                }
            }
            .t_box{
                margin-bottom: 10px;
                .ttxt{
                    width: 90px;
                    float: left;
                    color: #666;
                    font-size: 15px;
                    line-height: 38px;
                }
                .t_data{
                    width: calc(100% - 90px);
                    float: right;
                    .data{
                        float: left;
                        border: 1px solid rgba(var(--themecolor),0.75);
                        border-radius: 3px;
                        position: relative;
                        overflow: hidden;
                        margin: 0px 15px 10px 0px;
                        padding: 4px 45px 4px 10px;
                        .tit{
                            color: #666;
                            font-size: 14px;
                        }
                        .d{
                            display: inline-block;
                            color: #666;
                            font-size: 12px;
                            border: 1px solid #ddd;
                            border-radius: 20px;
                            background-color: #fff;
                            height: 20px;
                            line-height: 20px;
                            padding: 0px 5px 0px 12px;
                            margin: 4px 4px;
                            i{
                                margin-left: 6px;
                                color: #999;
                                font-size: 14px;
                            }
                            i:hover{
                                color: rgba(var(--themecolor),1);
                                cursor: pointer;
                            }
                        }
                        .close{
                            width: 40px;
                            text-align: center;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            top:0;
                            background-color: rgba(var(--themecolor),0.75);
                            i{
                                display: block;
                                color: #f0ecec;
                                font-size:20px;
                                height: 20px;
                                line-height: 20px;
                                width: 100%;
                                position: relative;
                                top: calc(50% - 10px);
                                transition: all 0.2s;
                                -webkit-transition: all 0.2s;
                            }
                        }
                        .close:hover{
                            cursor: pointer;
                        }
                        .close:hover i{
                            transform: rotate(180deg);
                            -webkit-transform: rotate(180deg);
                        }
                    }
                }
            }
        }
        .college_list{
            width: 100%;
            padding: 10px 0;
            min-height: 300px;
            .college_box{
                margin: 10px 20px;
                border: 1px solid #e7e5e5;
                box-shadow: 0px 0px 3px 1px #ebeaea;
                border-radius: 5px;
                overflow: hidden;
                background-color: #fff;
                .logo{
                    width: 80px;
                    height: 80px;
                    overflow: hidden;
                    margin: 20px;
                    float: left;
                    cursor: pointer;
                }
                .logo:hover{
                    opacity: 0.8;
                }
                .mark{
                    float: left;
                    margin-top: 15px;
                    width: calc(100% - 300px);
                    .name{
                        color: #666;
                        font-size: 18px;
                        margin-right: 20px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        cursor: pointer;
                    }
                    .name:hover{
                        color: rgba(var(--themecolor),0.75);
                    }
                    .con{
                        display: inline-block;
                    }
                    .txt{
                        color: #666;
                        font-size: 14px;
                        margin-right: 20px;
                        i{
                            margin-right: 5px;
                            color: #a39f9f;
                            font-size: 13px;
                        }
                    }
                    .dress{
                        width: 100%;
                        line-height: 20px;
                        margin-top: 10px;
                        color: #777;
                        font-size: 13px;
                        span{
                            display: inline-block;
                            margin-right: 20px;
                            i{
                                margin-right: 4px;
                            }
                        }
                    }
                    .tips{
                        margin-top: 10px;
                        height: 24px;
                        overflow: hidden;
                        position: relative;
                        padding-right: 60px;
                        margin-bottom: 10px;
                        span{
                            display: inline-block;
                            padding: 1px 10px;
                            background-color: rgba(var(--themecolor),0.1);
                            color: #777575;
                            font-size: 12px;
                            margin: 0px 6px 5px 0px;
                            border-radius: 20px;
                        }
                        .more{
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 60px;
                            height: 24px;
                            line-height: 24px;
                            text-align: center;
                            background-color: #fff;
                            color: #888;
                            font-size: 12px;
                        }
                        .more:hover{
                            cursor: pointer;
                            color: rgba(var(--themecolor),0.75);
                        }
                    }
                }
                .btnbox{
                    width: 160px;
                    float: right;
                    height: 100%;
                    .btn{
                        width: 120px;
                        margin: auto;
                        margin-top: 43px;
                        background-color: #faf3f3;
                        border: 1px solid rgba(var(--themecolor),0.4);
                        color: #666;
                        font-size: 14px;
                        letter-spacing: 1px;
                        text-align: center;
                        height: 32px;
                        line-height: 32px;
                        border-radius:3px;
                        i{
                            font-size: 16px;
                            margin-left: 5px;
                        }
                    }
                    .btn:hover{
                        cursor: pointer;
                        color: rgba(var(--themecolor),1);
                    }
                    .btn.remove{
                        color: #fff;
                        background-color: rgba(var(--themecolor),0.75);
                        border: 1px solid rgba(var(--themecolor),0.75);
                    }
                    .btn.remove:hover{
                        background-color: rgba(var(--themecolor),1);
                        border: 1px solid rgba(var(--themecolor),1);
                    }
                }
            }
            .pages{
                width: 100%;
                min-height: 50px;
                line-height: 50px;
                color: #666;
                font-size: 16px;
                text-align: center;
                letter-spacing: 1px;
                margin-bottom: 10px;
                user-select: none;
                .iconfont{
                    font-size: 50px;
                    display: block;
                    margin-top: 40px;
                    color: #9b9b9b;
                }
            }
            .pages:hover{
                color: rgb(var(--themecolor));
                cursor: pointer;
            }
        }
        .compare_box{
            position: fixed;
            width: 240px;
            height: 476px;
            top: 181px;
            z-index: 21;
            .compare{
                position: relative;
                width: 100%;
                height: 100%;
                .open{
                    position: absolute;
                    left: -48px;
                    top: 140px;
                    width: 48px;
                    height: 200px;
                    border-right: none;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    background-color: rgba(var(--themecolor),1);
                    cursor: pointer;
                    .ico{
                        width: 18px;
                        height: 100%;
                        line-height: 200px;
                        text-align: center;
                        color: #e9e8e8;
                        font-size: 13px;
                        float: left;
                        transition: all 0.3s;
                        -webkit-transition: all 0.3s;
                    }
                    .tit{
                        width: 14px;
                        height: 108px;
                        color: #e9e8e8;
                        font-size: 16px;
                        line-height: 22px;
                        margin-left: 2px;
                        margin-top: 30px;
                        float: left;
                        position: relative;
                        .num{
                            position: relative;
                            bottom: -25px;
                            left: -20px;
                            width: 46px;
                            padding: 5px 0px;
                            text-align: center;
                            border-bottom-left-radius: 5px;
                            background-color: #fff;
                            color: rgba(var(--themecolor),1);
                            font-weight: bold;
                            overflow: hidden;
                            border: 1px solid rgba(var(--themecolor),1);
                        }
                    }
                }
                .open:hover .ico{
                    animation: animate_ico 1s ease 0s infinite;
                    -webkit-animation: animate_ico 1s ease 0s infinite;
                    @-webkit-keyframes animate_ico {
                        0% {
                            opacity: 1;
                        }
                        50% {
                            opacity: 0.3;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
                    @keyframes animate_ico {
                        0% {
                            opacity: 1;
                            font-size: 13px;
                        }
                        50% {
                            opacity: 0.3;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
                }
                
                .db{
                    position: absolute;
                    left: -48px;
                    top: 365px;
                    width: 48px;
                    height: 48px;
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),1);
                    margin-top: 5px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    text-align: center;
                    span{
                        letter-spacing: 2px;
                        display: inline-block;
                        width: 28px;
                        margin-top: 7px;
                        font-size: 12px;
                        color: #fff;
                        line-height: 16px;
                    }
                }
                .list{
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    border-radius: 5px;
                    border: 1px solid #cecece;
                    .search{
                        width: 100%;
                        height: 36px;
                        position: relative;
                        margin-top: 10px;
                        i{
                            display: inline-block;
                            width: 30px;
                            height: 36px;
                            text-align: center;
                            line-height: 40px;
                            color: #999;
                            font-size: 14px;
                            position: absolute;
                            left: 10px;
                            top: 0px;
                            z-index: 1;
                        }
                        .el-select{
                            display: inline-block;
                            width: 220px;
                            margin-left: 10px;
                        }
                    }
                    .dlist{
                        margin: 10px 10px 0;
                        height: 327px;
                        .comparescroll{
                            height: 100%;
                            width: 222px;
                        }
                        .dl:last-child{
                            border-bottom: none;
                        }
                        .dl{
                            width: 100%;
                            height: 40px;
                            border-bottom: 1px solid #dddada;
                            position: relative;
                            .check{
                                display: block;
                                width: 40px;
                                height: 40px;
                                position: absolute;
                                left: 0px;
                                cursor: pointer;
                                .ch{
                                    width: 16px;
                                    height: 16px;
                                    border: 1px solid #ccc9c9;
                                    border-radius: 3px;
                                    overflow: hidden;
                                    display: block;
                                    margin: 11px 0px 0px 0px;
                                    i{
                                        font-size: 14px;
                                        color: #fff;
                                        display: none;
                                    }
                                }
                            }
                            .check:hover .ch{
                                border: 1px solid rgba(var(--themecolor),0.65);
                            }
                            .sname{
                                float: left;
                                width: 188px;
                                height: 100%;
                                .el-button{
                                    color: #666;
                                    font-size: 14px;
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    line-height: 38px;
                                    overflow: hidden;
                                    border-color: transparent;
                                    background-color: transparent;
                                    padding: 0;
                                    overflow:hidden;text-overflow:ellipsis;white-space:nowrap;
                                    text-align: left;
                                    text-indent: 28px;
                                }
                                .el-button:active{
                                    border-color: transparent;
                                }
                                .el-button:focus, .el-button:hover{
                                    background-color: transparent;
                                }
                            }
                            .sname:hover  .el-button{
                                color: rgba(var(--themecolor),0.75);
                            }
                            .delete{
                                color: #999;
                                font-size: 16px;
                                display: block;
                                float: left;
                                width: 32px;
                                height: 100%;
                                line-height: 40px;
                                text-align: center;
                            }
                            .delete:hover{
                                cursor: pointer;
                                color: rgba(var(--themecolor),1);
                            }
                        }
                        .dl.on .check .ch{
                            background-color: rgba(var(--themecolor),0.75);
                            border: 1px solid rgba(var(--themecolor),0.75);
                        }
                        .dl.on .check .ch:hover{
                            cursor: pointer;
                            background-color: rgba(var(--themecolor),1);
                            border: 1px solid rgba(var(--themecolor),1);
                        }
                        .dl.on .check .ch i{
                            display: block;
                            line-height: 18px;
                        }
                        .mark{
                            text-align: center;
                            line-height: 40px;
                            color: #666;
                            font-size: 12px;
                        }
                        .btn{
                            width: 160px;
                            height: 38px;
                            text-align: center;
                            line-height: 38px;
                            color: #fff;
                            font-size: 14px;
                            background-color: rgba(var(--themecolor),0.75);
                            cursor: pointer;
                            letter-spacing: 1px;
                            margin: auto;
                            border-radius: 5px;
                            transition: all 0.2s;
                            -webkit-transition: all 0.2s;
                        }
                        .btn:hover{
                            background-color: rgba(var(--themecolor),1);
                        }
                    }
                }
            }
        }
        .compare_box.animate_open{
            animation: animate_open 0.3s ease 0s 1;
            -webkit-animation: animate_open 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes animate_open {
                from {
                    right: -240px;
                }
                to {
                    right: 0px;
                }
            }
            @keyframes animate_open {
                from {
                    right: -240px;
                }
                to {
                    right: 0px;
                }
            }
        }
        .compare_box.animate_close{
            animation: animate_close 0.3s ease 0s 1;
            -webkit-animation: animate_close 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes animate_close {
                from {
                    right: 0px;
                }
                to {
                    right: -240px;
                }
            }
            @keyframes animate_close {
                from {
                    right: 0px;
                }
                to {
                    right: -240px;
                }
            }
        }
    }
}
.close_layer_back{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 19;
    width: 100%;
    height: 100%;
    display: none;
    opacity: 0;
    background-color: rgba(0,0,0,0.5);
}
.close_layer_back.layerbackshow {
    animation: layerbackshow 0.3s ease 0s 1;
    -webkit-animation: layerbackshow 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    @-webkit-keyframes layerbackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes layerbackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
.close_layer_back.layerbackhide {
    animation: layerbackhide 0.3s ease 0s 1;
    -webkit-animation: layerbackhide 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes layerbackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @keyframes layerbackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}
.el-select-dropdown__item.selected{
    color: rgba(var(--themecolor),1);
}
</style>
<style lang="less">
.college_box .college_con .searchbox .el-input.is-active .el-input__inner, .college_box .college_con .searchbox .el-input .el-input__inner:focus{
    border: 1px solid rgba(var(--themecolor),0.75);
}
.college_box .college_con .compare_box .el-input .el-input__inner{
    text-indent: 28px;
    color: #666;
    height: 36px;
    padding: 0;
}
.college_box .college_con .compare_box .el-input__icon{
    line-height: normal;
}
.college_box .college_con .compare_box .el-select .el-input__inner:focus{
    border-color:rgba(var(--themecolor),0.75);
}
.college_box .college_con .compare_box .el-range-editor.is-active, .college_box .college_con .compare_box .el-range-editor.is-active:hover, .college_box .college_con .compare_box .el-select .el-input.is-focus .el-input__inner{
    border-color:rgba(var(--themecolor),0.75);
}
.college_box .college_con .compare_box .el-scrollbar__wrap{
    overflow-x: hidden;
}
</style>